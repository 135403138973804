<template>
<div class="email-text">
    <div class="card-title mt-8">
        <h2>Update My Watch and Exclusion Lists</h2>
        <!-- <vs-button @click="popupActive=true" color="gray" type="flat" icon-pack="feather" icon="icon-help-circle" class="p-0 help-btn"></vs-button> -->
    </div>
    <div class="watch-list">
        <vs-tabs v-model="activeTab">
            <vs-tab label="Watch List" icon-pack="feather" icon="icon-eye">
                <div class="tab-text">
                    <vs-table ref="tableData" v-model="selected" pagination :max-items="itemsPerPage" search :data="watchlist">
                        <div slot="header" class="flex justify-between watch-table-header">
                            <vs-button @click="watchlistpupActive=true" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn float-left bg-actionbutton">{{ LabelConstant.buttonLabelAddCompanyToWatchList }}</vs-button>
                        </div>
                        <template slot="thead">
                            <vs-th>Action</vs-th>
                            <vs-th sort-key="watch">Watch these Companies</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <tbody>
                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                    <vs-td width="50">
                                        <vs-button @click="deleteWatchList('W',tr.Company_Name)" :title="LabelConstant.buttonLabelDelete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                                    </vs-td>
                                    <vs-td>
                                        <p>{{ tr.Company_Name }}</p>
                                    </vs-td>
                                </vs-tr>
                            </tbody>
                        </template>
                    </vs-table>
                    <p class="table-data-entries-details" v-if="!isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingDataTo}} of {{watchlist.length|tableRecordNumberFormatter}} entries</p>
                    <p class="table-data-entries-details" v-if="isSearch && isTableRecordEntriesDisplay">Showing {{showingDataFrom}} to {{showingSearchedDataTo}} of {{totalSearchedData|tableRecordNumberFormatter}} entries (filtered from {{watchlist.length|tableRecordNumberFormatter}} entries)</p>
                    <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplay">Showing 0 to 0 of 0 entries </p>
                </div>
            </vs-tab>
            <vs-tab label="Exclusion List" icon-pack="feather" icon="icon-list">
                <div class="tab-text">
                    <vs-table ref="tableDataExclusionList" v-model="selected" pagination :max-items="itemsPerPage" search :data="exclusionlist">
                        <div slot="header" class="flex justify-between">
                            <vs-button @click="exclusionlistActive=true" type="filled" icon-pack="feather" icon="icon-plus" class="small-btn float-left bg-actionbutton">{{ LabelConstant.buttonLabelAddCompanyToExludeList }}</vs-button>
                        </div>
                        <template slot="thead">
                            <vs-th>Action</vs-th>
                            <vs-th sort-key="watch">Exclude these Companies</vs-th>
                        </template>
                        <template slot-scope="{data}">
                            <tbody>
                                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                                    <vs-td class="whitespace-no-wrap">
                                        <vs-button @click="deleteWatchList('E',tr.Company_Name)" :title="LabelConstant.buttonLabelDelete" color="danger" type="filled" icon-pack="feather" icon="icon-trash-2" class="mr-0"></vs-button>
                                    </vs-td>
                                    <vs-td>
                                        <p>{{ tr.Company_Name }}</p>
                                    </vs-td>
                                </vs-tr>
                            </tbody>
                        </template>
                    </vs-table>
                    <p class="table-data-entries-details" v-if="!isSearchExclusionList && isTableRecordEntriesDisplayExclusionList">Showing {{showingDataFromExclusionList}} to {{showingDataToExclusionList}} of {{exclusionlist.length|tableRecordNumberFormatter}} entries</p>
                    <p class="table-data-entries-details" v-if="isSearchExclusionList && isTableRecordEntriesDisplayExclusionList">Showing {{showingDataFromExclusionList}} to {{showingSearchedDataToExclusionList}} of {{totalSearchedDataExclusionList|tableRecordNumberFormatter}} entries (filtered from {{exclusionlist.length|tableRecordNumberFormatter}} entries)</p>
                    <p class="table-data-entries-details" v-if="!isTableRecordEntriesDisplayExclusionList">Showing 0 to 0 of 0 entries </p>
                </div>
            </vs-tab>
        </vs-tabs>
    </div>
    <!-- Help popup -->
    <help-modal :popupActive="popupActive" @close="closepopup"></help-modal>
    <!-- Delete Popup -->
    <vs-popup class="delete-popup" :active.sync="deletepopupActive">
        <template>
            <div class="vx-row mb-3">
                <div class="vx-col w-full">
                    <div class="popup-icon">
                        <feather-icon icon="Trash2Icon"></feather-icon>
                    </div>
                    <h4>{{deleteText}}</h4>
                    <span class="deleted-no">{{ deleteCompanyName }}</span>
                </div>
            </div>
        </template>
        <div class="float-right mt-6 mb-8">
            <vs-button type="filled" @click="type == 'W'?deleteEntryWatch():deleteEntryExclude()" color="danger">{{ LabelConstant.buttonLabelDelete }}</vs-button>
            <vs-button @click="deletepopupActive = false" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
        </div>
    </vs-popup>
    <!-- New Watch List Entry -->
    <vs-popup class="tag-info list-entry" title="Create New Watch List Entry" :active.sync="watchlistpupActive">
        <form @submit.prevent="checkValidation()" autocomplete="off" data-vv-scope="watchlist">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col input-text w-full">
                        <p>Immediately alert on new visitors. When a visitor with this company name visits your website, you will receive an instant notification.</p>
                        <div class="popup-icon">
                            <img src="../../../src/assets/images/create-list.svg" />
                        </div>
                        <vs-input type="text" class="w-full" label-placeholder="Company Name" v-model="watchListCompanyName" name="watchListCompanyName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{ errors.first('watchlist.watchListCompanyName') }}</span>

                        <p>Notify me immediately if a company with a name containing the above visits my site.</p>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button type="filled" button="submit" color="primary">{{ LabelConstant.buttonLabelCreateWatchListEntry }}</vs-button>
                <vs-button @click="cancelWatchlist()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </form>
    </vs-popup>
    <!-- New Watch List Entry -->
    <vs-popup class="tag-info list-entry" title="Create New Exclusion List Entry" :active.sync="exclusionlistActive">
        <form @submit.prevent="excludecheckValidation()" autocomplete="off" data-vv-scope="exclude">
            <template>
                <div class="vx-row mb-3">
                    <div class="vx-col input-text w-full">
                        <p>Do not alert on this company again. When a visitor with this company name visits your website, you will not receive a notification.</p>
                        <div class="popup-icon">
                            <img src="../../../src/assets/images/create-list.svg" />
                        </div>
                        <vs-input type="text" class="w-full" label-placeholder="Company Name" name="excludeCompanyName" v-model="excludeCompanyName" v-validate="'required'" />
                        <span class="text-danger text-sm">{{ errors.first('exclude.excludeCompanyName') }}</span>
                        <p>Do not notify me if a company with a name containing the above visits my site.</p>
                    </div>
                </div>
            </template>
            <div class="float-right mt-6 mb-8">
                <vs-button type="filled" button="submit" color="primary">{{ LabelConstant.buttonLabelCreateExclusionListEntry }}</vs-button>
                <vs-button @click="cancelExcludeList()" color="grey" type="border" class="ml-4 grey-btn">{{ LabelConstant.buttonLabelCancel }}</vs-button>
            </div>
        </form>
    </vs-popup>
</div>
</template>

<script>
import VxCard from "@/components/vx-card/VxCard";
import HelpModal from "../../components/modal/HelpModal";
import {
    Validator
} from "vee-validate";
const dict = {
    custom: {
        excludeCompanyName: {
            required: "A company name is required."
        },
        watchListCompanyName: {
            required: "A company name is required."
        }
    }
};
Validator.localize("en", dict);
export default {
    components: {
        VxCard,
        HelpModal
    },
    data() {
        return {
            /* Popup */
            popupActive: false,
            deletepopupActive: false,
            watchlistpupActive: false,
            exclusionlistActive: false,
            selected: [],
            watchlist: [],
            exclusionlist: [],
            excludeCompanyName: "",
            watchListCompanyName: "",
            itemsPerPage: 5,
            activeTab: 0,
            deleteText: null,
            deleteCompanyName: null,
            type: null,
        };
    },
    created() {
        this.getWatchList();
        this.getExcludeList();
    },
    methods: {
        /* Popup Close */
        closepopup() {
            this.popupActive = false;
        },
        /** close watch list popup */
        cancelWatchlist() {
            this.watchListCompanyName = null;
            this.watchlistpupActive = false;
            setTimeout(() => {
                this.errors.clear();
            }, 50);
        },
        /** close exclude list popup */

        cancelExcludeList() {
            this.excludeCompanyName = null;
            this.exclusionlistActive = false;
            setTimeout(() => {
                this.errors.clear();
            }, 50);
        },
        /** delete watch list */
        deleteWatchList(type, name) {
            let text;
            if (type == "W") {
                text = "Delete Watch List Entry?";

            } else {
                text = "Delete Exlusion List Entry?";
            }
            this.type = type;
            this.deleteText = text;
            this.deleteCompanyName = name;
            this.deletepopupActive = true;
        },
        async excludecheckValidation() {
            this.$validator.validateAll("exclude").then(async result => {
                if (result) {
                    this.createExcludeListEntry();
                }
            });
        },
        async checkValidation() {
            this.$validator.validateAll("watchlist").then(async result => {
                if (result) {
                    this.createWatchListEntry();
                }
            });
        },
        /** get data watchlist */
        async getWatchList() {
            this.$vs.loading();
            await this.axios
                .get("/ws/WatchList/GetWatchList")
                .then(response => {
                    let data = response.data;
                    this.watchlist = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        /** get data excludelist */

        async getExcludeList() {
            this.$vs.loading();
            await this.axios
                .get("/ws/WatchList/GetExcludeList")
                .then(response => {
                    let data = response.data;
                    this.exclusionlist = data;
                    this.$vs.loading.close();
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                });
        },
        /** create new record in watchlist */

        async createWatchListEntry() {
            this.$vs.loading();
            let createWatchListEntry = {
                Company_Name: this.watchListCompanyName
            };
            await this.axios
                .post("/ws/WatchList/CreateWatchListEntry", createWatchListEntry)
                .then(() => {

                    this.watchListCompanyName = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.watchlistpupActive = false;
                    this.activeTab = 0;
                    this.getWatchList();
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Your watch list has been updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);

                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        /** create new record in exclude list */
        async createExcludeListEntry() {
            this.$vs.loading();
            let CreateExcludeListEntry = {
                Company_Name: this.excludeCompanyName
            };
            await this.axios
                .post("/ws/WatchList/CreateExcludeListEntry", CreateExcludeListEntry)
                .then(() => {

                    this.siteUrl = null;
                    this.excludeCompanyName = null;
                    setTimeout(() => {
                        this.errors.clear();
                    }, 100);
                    this.exclusionlistActive = false;
                    this.getExcludeList();
                    //this.activeTab = 0;
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: "Your Exclusion list has been updated successfully.",
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);

                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        /** delete Entry watch list entry */
        async deleteEntryWatch() {
            this.$vs.loading();
            let deleteEntry = {
                Company_Name: this.deleteCompanyName
            };
            await this.axios
                .post('/ws/WatchList/DeleteWatchListEntry', deleteEntry)
                .then(() => {

                    let successText;
                    this.deletepopupActive = false;
                    this.getWatchList();
                    this.activeTab = 0;
                    successText = "Company successfully removed from your watch list.";
                    this.deleteCompanyName = null;
                    this.type = null;
                    this.deleteText = null;
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: successText,
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);

                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
        /** delete Entry exclude list entry */

        async deleteEntryExclude() {
            this.$vs.loading();
            let deleteEntry = {
                Company_Name: this.deleteCompanyName
            };
            await this.axios
                .post('/ws/WatchList/DeleteExcludeListEntry', deleteEntry)
                .then(() => {
                    let successText;
                    this.deletepopupActive = false;

                    this.getExcludeList();
                    successText = "Company successfully removed from your exclusion list.";
                    this.activeTab = 1;

                    this.deleteCompanyName = null;
                    this.type = null;
                    this.deleteText = null;
                    this.$vs.loading.close();
                    this.$vs.notify({
                        title: "Success",
                        text: successText,
                        color: "success",
                        iconPack: "feather",
                        icon: "icon-check",
                        position: "top-right",
                        time: 4000
                    });
                })
                .catch(e => {
                    this.$vs.loading.close();
                    this.showError(e);
                    this.$vs.notify({
                        title: "Error",
                        text: e.response.data.Errors[0].Message,
                        color: "danger",
                        iconPack: "feather",
                        icon: "icon-alert-circle",
                        position: "top-right",
                        time: 4000
                    });
                });
        },
    },
    computed: {
        isTableRecordEntriesDisplay() {
            if (this.watchlist.length > 0) {
                if (this.$refs.tableData.datax.length > 0) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        isSearch: function () {
            if (this.watchlist.length > 0) {
                if (this.$refs.tableData.searchx == null || this.$refs.tableData.searchx == "") {
                    return false
                } else {
                    return true
                }
            }
        },
        showingDataFrom: function () {
            if (this.watchlist.length > 0) {
                return ((this.$refs.tableData.currentx - 1) * this.itemsPerPage) + 1
            }
        },
        showingDataTo: function () {
            if (this.watchlist.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPages
                if (totalPages == this.$refs.tableData.currentx) {
                    return ((this.$refs.tableData.currentx - 1) * this.itemsPerPage) + this.$refs.tableData.datax.length
                } else {
                    return ((this.$refs.tableData.currentx) * this.itemsPerPage)
                }
            }
        },
        totalSearchedData: function () {
            if (this.watchlist.length > 0) {
                return this.$refs.tableData.queriedResults.length
            }
        },
        showingSearchedDataTo: function () {
            if (this.watchlist.length > 0) {
                let totalPages = this.$refs.tableData.getTotalPagesSearch
                if (totalPages == this.$refs.tableData.currentx) {
                    return ((this.$refs.tableData.currentx - 1) * this.itemsPerPage) + this.$refs.tableData.datax.length
                } else {
                    return ((this.$refs.tableData.currentx) * this.itemsPerPage)
                }
            }
        },

        isTableRecordEntriesDisplayExclusionList() {
            if (this.exclusionlist.length > 0 && this.$refs.tableDataExclusionList) {
                if (this.$refs.tableDataExclusionList.datax.length > 0) {
                    return true
                } else {
                    return false
                }
            } else {
                return false
            }
        },
        isSearchExclusionList: function () {
            if (this.exclusionlist.length > 0 && this.$refs.tableDataExclusionList) {
                if (this.$refs.tableDataExclusionList.searchx == null || this.$refs.tableDataExclusionList.searchx == "") {
                    return false
                } else {
                    return true
                }
            }
        },
        showingDataFromExclusionList: function () {
            if (this.exclusionlist.length > 0) {
                return ((this.$refs.tableDataExclusionList.currentx - 1) * this.itemsPerPage) + 1
            }
        },
        showingDataToExclusionList: function () {
            if (this.exclusionlist.length > 0) {
                let totalPages = this.$refs.tableDataExclusionList.getTotalPages
                if (totalPages == this.$refs.tableDataExclusionList.currentx) {
                    return ((this.$refs.tableDataExclusionList.currentx - 1) * this.itemsPerPage) + this.$refs.tableDataExclusionList.datax.length
                } else {
                    return ((this.$refs.tableDataExclusionList.currentx) * this.itemsPerPage)
                }
            }
        },
        totalSearchedDataExclusionList: function () {
            if (this.exclusionlist.length > 0) {
                return this.$refs.tableDataExclusionList.queriedResults.length
            }
        },
        showingSearchedDataToExclusionList: function () {
            if (this.exclusionlist.length > 0) {
                let totalPages = this.$refs.tableDataExclusionList.getTotalPagesSearch
                if (totalPages == this.$refs.tableDataExclusionList.currentx) {
                    return ((this.$refs.tableDataExclusionList.currentx - 1) * this.itemsPerPage) + this.$refs.tableDataExclusionList.datax.length
                } else {
                    return ((this.$refs.tableDataExclusionList.currentx) * this.itemsPerPage)
                }
            }
        }
    }
};
</script>
